export const menu = {
  accounts: 'Accounts',
  recipients: 'Recipients',
  activity: 'Activity',
  logout: 'Log out',
  title: '{{companyName}} Global Accounts',
  johnDoe: 'John Doe',
  onboardingProcess: 'Onboarding process',
  demo: 'Demo',
} as const;
