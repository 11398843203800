import { TAccountDto } from '@payler/api/client-office';

export const getExchangeFilteredReceiverAccountsByAccountForCNY = (
  accounts: TAccountDto[] | null,
) => {
  if ( !accounts) {
    return [];
  }
  const result = accounts.filter((item) => {
    return (
      item.currency === 'cny' &&
      item.allowIncomingOperations
    );
  });
  
  return result
};
