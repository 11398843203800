import React from 'react';
import { HStack, Radio, useRadioGroup } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import {
  FormOption,
  FormOptionProps,
} from '../components/FormOption/FormOption';

export interface RadioFieldsProps {
  options: { label?: string; value: string; checked?: boolean }[];
  name: string;
  label?: FormOptionProps['label'];
}

export const RadioField: React.FC<RadioFieldsProps> = (props) => {
  const { control } = useFormContext();
  const {
    field,
    formState: { errors, touchedFields },
  } = useController({
    control,
    name: props.name,
  });
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: props.name,
    onChange: field.onChange,
    value: field.value,
  });
  const errorMessage = errors[props.name]?.message;
  const isError = !!errorMessage && !!touchedFields;
  const group = getRootProps();
  return (
    <FormOption
      isInvalid={isError}
      label={props.label}
      errorMessage={errorMessage as string | undefined}
    >
      <HStack {...group}>
        {props.options.map((item, index) => {
          const radio = getRadioProps({
            value: item.value,
            checked: !!item.checked,
          });
          return (
            <Radio key={index} {...radio}>
              {item.label}
            </Radio>
          );
        })}
      </HStack>
    </FormOption>
  );
};
RadioField.displayName = 'RadioField';
