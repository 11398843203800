import { useMemo } from 'react';
import { TCurrency } from '../types';

export const ALL_CURRENCIES: {
  id: TCurrency;
  name: string;
  currencyCode: Uppercase<TCurrency>;
}[] = [
  {
    id: 'eur',
    name: 'Euro',
    currencyCode: 'EUR',
  },
  {
    id: 'gbp',
    name: 'Pound Sterling',
    currencyCode: 'GBP',
  },
  {
    id: 'usd',
    name: 'Dollar',
    currencyCode: 'USD',
  },
  {
    id: 'cad',
    name: 'Canadian Dollar',
    currencyCode: 'CAD',
  },
  {
    id: 'chf',
    name: 'Swiss Franc',
    currencyCode: 'CHF',
  },
  {
    id: 'dkk',
    name: 'Danish Krone',
    currencyCode: 'DKK',
  },
  {
    id: 'nok',
    name: 'Norwegian Krone',
    currencyCode: 'NOK',
  },
  {
    id: 'pln',
    name: 'Polish Zloty',
    currencyCode: 'PLN',
  },
  {
    id: 'sek',
    name: 'Swedish Krona',
    currencyCode: 'SEK',
  },
  {
    id: 'cny',
    name: 'Chinese yuan renminbi',
    currencyCode: 'CNY',
  },
];

export const namedCurrencyOptions: Array<{label: string, value: TCurrency}> = [
  {
    label: 'Pound Sterling - GBP',
    value: 'gbp',
  },
  { label: 'Euro - EUR', value: 'eur' },
  { label: 'Dollar - USD', value: 'usd' },
  { label: 'Canadian Dollar - CAD', value: 'cad' },
  { label: 'Swiss Franc - CHF', value: 'chf' },
  { label: 'Danish Krone - DKK', value: 'dkk' },
  { label: 'Norwegian Krone - NOK', value: 'nok' },
  { label: 'Polish Zloty - PLN', value: 'pln' },
  { label: 'Swedish Krona - SEK', value: 'sek' },
  { label: 'Chinese Yuan - CNY', value: 'cny' },
];

export const useCurrencyOptions = () => {
  return useMemo(
    () =>
      ALL_CURRENCIES.map((curr) => ({
        label: curr.currencyCode,
        value: curr.id,
      })),
    [],
  );
};

export const useCurrencyOptionsMap = () => {
  return useMemo(() => {
    return ALL_CURRENCIES.reduce(
      (acc, current) => {
        acc[current.id] = current
        return acc;
      },
      {} as Record<
        TCurrency,
        { id: TCurrency; name: string; currencyCode: Uppercase<TCurrency> }
      >,
    );
  }, []);
};