import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormOption,
  FormOptionProps,
} from '../components/FormOption/FormOption';
import {
  SegmentedPicker,
  SegmentedPickerProps,
} from '../components/SegmentedPicker/SegmentedPicker';

export interface SegmentedPickerFieldProps
  extends Omit<SegmentedPickerProps, 'onSelectionChange'> {
  name: string;
  defaultValue?: boolean;
  label?: FormOptionProps['label'];
}

export const SegmentedPickerField: React.FC<SegmentedPickerFieldProps> = (
  props
) => {
  const {
    control,
    formState: { errors, touchedFields },
  } = useFormContext();
  const { name, defaultValue, ...rest } = props;
  const errorMessage = errors[name]?.message;
  const isError = !!errorMessage && !!touchedFields;
  return (
    <FormOption
      isInvalid={isError}
      label={props?.label}
      errorMessage={errorMessage as string | undefined}
    >
      <Controller
        control={control}
        name={name}
        defaultValue={!!defaultValue}
        render={({ field: { onChange, value } }) => (
          <SegmentedPicker
            {...rest}
            selection={value}
            onSelectionChange={(newSelection) => {
              onChange(newSelection);
            }}
          />
        )}
      />
    </FormOption>
  );
};
SegmentedPickerField.displayName = 'SegmentedPickerField';
