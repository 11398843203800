import { BankModal } from '../../components/BankModal/BankModal';
import { useRecoilBoolean, useRecoilBooleanValue } from '@payler/bank-utils';
import {
  AddRecipientWizard,
  TAddRecipientWizardStep,
} from './AddRecipientWizard';

const KEY = 'modals:AddRecipientModal';

export const useShowAddRecipientModal = () => useRecoilBoolean(KEY).on;
const useHideAddRecipientModal = () => useRecoilBoolean(KEY).off;
const useIsOpenAddRecipientModal = () => useRecoilBooleanValue(KEY);

export const AddRecipientModal = ({
  initialStep,
}: {
  initialStep?: TAddRecipientWizardStep;
}) => {
  const isOpen = useIsOpenAddRecipientModal();
  const hideModal = useHideAddRecipientModal();

  return (
    <BankModal close={hideModal} open={isOpen} closeOnOverlayClick={false}>
      <AddRecipientWizard initialStep={initialStep} />
    </BankModal>
  );
};
