import { Box, Flex, Link, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { AccountInfo } from '../../components/AccountInfo/AccountInfo';
import { AccountsBlock } from '../../components/AccountsBlock/AccountsBlock';
import { AllAccountsDrawer } from '../../components/AllAccountsDrawer/AllAccountsDrawer';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import { useLayoutContext } from '../../context/LayoutContextProvider';
import { IncomingTransferInfoDrawer } from '../../drawers/IncomingTransferInfoDrawer/IncomingTransferInfoDrawer';
import { OutgoingTransferInfoDrawer } from '../../drawers/OutgoingTransferInfoDrawer/OutgoingTransferInfoDrawer';
import {
  useAccountByUrl,
  useAccountIdFromUrl,
} from '../../hooks/accounts/queries';
import { ContentLayout } from '../../layouts/ContentLayout/ContentLayout';
import { AccountStatementModal } from '../../modals/AccountStatement';
import { AddAccountModal } from '../../modals/AddAccount';
import { EditAccountNameModal } from '../../modals/EditAccountName';
import { TransferModal } from '../../modals/TransferModal/TransferModal';
import { Link as RouterDomLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TextStyles } from '@payler/ui-theme';
import { AddMoneyModal } from '../../modals/AddMoney/AddMoney';
import { ExchangeModal } from '../../modals/Exchange/ExchangeModal';
import { FxTransferInfoDrawer } from '../../drawers/FxTransferInfoDrawer/FxTransferInfoDrawer';
import { OnboardingProgressBlock } from '../../components/OnboardingProgressBlock/OnboardingProgressBlock';
import { usePermissions } from '../../hooks/use-permissions';
import { ActivityFilteredTable } from '../../tables/ActivityFilteredTable/ActivityFilteredTable';
import { useIsActiveClient } from '../../hooks/clients/helpers';
import { useAccountWarnings } from '../../hooks/use-account-warning';
import { ConvertToCNYModal } from '../../modals/ConvertToCNY/ConvertToCNYModal';

export const AccountsPage = () => {
  const { isMobile, isTablet } = useLayoutContext();

  return (
    <React.Suspense fallback={<LoaderBox variant="table" />}>
      <ContentLayout>
        <Flex justifyContent="center">
          <VStack
            w={isMobile ? '100%' : isTablet ? '448px' : '600px'}
            maxW="600px"
            spacing={2}
          >
            <AccountsBody />
          </VStack>
          <EditAccountNameModal />
          <AccountStatementModal />
          <AddAccountModal />
          <AddMoneyModal />
          <ExchangeModal />
          <ConvertToCNYModal />
        </Flex>
      </ContentLayout>
    </React.Suspense>
  );
};

const AccountsBody: React.FC = () => {
  const currentAccount = useAccountByUrl();
  const { isGlobalAccounts } = usePermissions();

  return (
    <>
      {!isGlobalAccounts && <OnboardingProgressBlock />}
      <ClientWarning />
      <AccountsBlock />
      <AccountWarning />
      <AccountInfo />
      <Activity />
      <AllAccountsDrawer />
      <IncomingTransferInfoDrawer />
      <OutgoingTransferInfoDrawer />
      <FxTransferInfoDrawer />
      <TransferModal initialSenderAccount={currentAccount} />
    </>
  );
};

const Activity: React.FC = () => {
  const { t } = useTranslation(['accounts']);
  const { isGlobalAccounts } = usePermissions();
  const accountId = useAccountIdFromUrl();

  return (
    <VStack spacing={1} w="inherit">
      <Flex w="100%" justifyContent="space-between">
        <Text textStyle={TextStyles.BodyText16Semibold} color="primary.500">
          {t('accounts:activity')}
        </Text>
        {isGlobalAccounts ? (
          <Link
            as={RouterDomLink}
            to={{ pathname: '/activity', search: `?AccountId=${accountId}` }}
            color="brands.500"
            textStyle={TextStyles.Subtitle14Medium}
            data-testid="link_activity_see-all"
          >
            {t('accounts:seeAll')}
          </Link>
        ) : (
          <Text
            color="brands.300"
            textStyle={TextStyles.Subtitle14Medium}
            pointerEvents="none"
          >
            {t('accounts:seeAll')}
          </Text>
        )}
      </Flex>
      <ActivityFilteredTable />
    </VStack>
  );
};

const ClientWarning = () => {
  const { t } = useTranslation(['accounts']);
  const isActiveClient = useIsActiveClient();
  const { isGlobalAccounts } = usePermissions();

  if (isActiveClient || !isGlobalAccounts) {
    return null;
  }

  return (
    <Box
      w="inherit"
      bg="red.500"
      borderRadius={1}
      border="1px solid"
      borderColor="red.300"
      px={2}
      py={1}
    >
      <Text
        textAlign="center"
        textStyle={TextStyles.Subtitle14Regular}
        color="secondary.500"
      >
        {t('accounts:allAccountsRestricted')}
      </Text>
    </Box>
  );
};

const AccountWarning = () => {
  const account = useAccountByUrl();
  const accountWarnings = useAccountWarnings(account);

  if (!accountWarnings.length) return null;

  return (
    <VStack w="inherit" spacing={1}>
      {accountWarnings.map((warning, index) => (
        <Box
          key={index}
          w="inherit"
          bg="red.500"
          borderRadius={1}
          border="1px solid"
          borderColor="red.300"
          px={2}
          py={1}
        >
          <Text
            textAlign="center"
            textStyle={TextStyles.Subtitle14Regular}
            color="secondary.500"
          >
            {warning.message}
          </Text>
        </Box>
      ))}
    </VStack>
  );
};
