import React, { PropsWithChildren, useMemo } from 'react';
import {
  DrawerBody,
  DrawerHeader,
  Flex,
  HStack,
  VStack,
  Text,
  Button,
  IconButton,
  Icon,
  Box,
} from '@chakra-ui/react';
import { Alert, NoData, ThinDivider } from '@payler/ui-components';
import { ArrowUp14Icon, CrossIcon } from '@payler/ui-icons';
import { TextStyles } from '@payler/ui-theme';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BankDrawer } from '../../components/BankDrawer/BankDrawer';
import { BankDrawerContent } from '../../components/BankDrawer/BankDrawerContent';
import {
  useRecipientIdFromUrl,
  useRecipientQuery,
  useSetRecipientIdToUrl,
} from '../../hooks/recipients/queries';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import {
  getRecipientTitle,
  useLocalizedRecipientType,
} from '../../helpers/recipient';
import { useRecipientIcon } from '../../hooks/recipients/icon';
import {
  TransferModal,
  useShowTransferModal,
} from '../../modals/TransferModal/TransferModal';
import { InfoItem } from '../helpers/InfoItem';
import { ALL_CURRENCIES, TrashIcon } from '@payler/bank-utils';
import { useParticipantBankInfo } from '../../hooks/use-participant-bank-info';
import { useAccountsQuery } from '../../hooks/accounts/queries';
import { TAccountDto } from '@payler/api/client-office';
import { useParticipantAddress } from '../../helpers/use-participant-address';

export const RecipientInfoDrawer = () => {
  const recipientId = useRecipientIdFromUrl();
  const isOpen = !!recipientId;
  const setRecipientId = useSetRecipientIdToUrl();

  const handleClose = useCallback(() => {
    setRecipientId();
  }, [setRecipientId]);

  return (
    <BankDrawer isOpen={isOpen} onClose={handleClose}>
      <BankDrawerContent drawerId="recipient-info-drawer">
        <React.Suspense fallback={<LoaderBox />}>
          <Content />
        </React.Suspense>
      </BankDrawerContent>
    </BankDrawer>
  );
};

const Content = () => {
  const { data: recipient } = useRecipientQuery();
  const { data: accounts } = useAccountsQuery();

  const isAccountsEmpty = useMemo(
    () =>
      !accounts?.some(
        (account: TAccountDto) =>
          account.currency.toLowerCase() ===
          recipient?.account.currency.toLowerCase(),
      ),
    [accounts, recipient?.account.currency],
  );

  const isAvailableAccountsEmpty = useMemo(
    () =>
      !accounts
        ?.filter(
          (account: TAccountDto) =>
            account.currency.toLowerCase() ===
            recipient?.account.currency.toLowerCase(),
        )
        .some((account) => account.allowOutgoingOperations),
    [accounts, recipient?.account.currency],
  );

  return (
    <>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <ThinDivider />
      <Actions isTransferAvailable={!isAvailableAccountsEmpty} />
      <ThinDivider />
      <Body
        isAccountsEmpty={isAccountsEmpty}
        isAvailableAccountsEmpty={isAvailableAccountsEmpty}
      />
    </>
  );
};

const HeaderWrapper: FC<PropsWithChildren> = ({ children }) => {
  const setRecipientId = useSetRecipientIdToUrl();

  return (
    <DrawerHeader p={3}>
      <HStack spacing={2} justifyContent="space-between">
        {children}
        <IconButton
          icon={<CrossIcon />}
          aria-label="close"
          variant="secondary"
          w={6}
          h={6}
          onClick={() => {
            setRecipientId();
          }}
          data-testid="button_drawer-close"
        />
      </HStack>
    </DrawerHeader>
  );
};

const Header = () => {
  const { data } = useRecipientQuery();
  const localizedType = useLocalizedRecipientType(data?.recipientType);
  const title = getRecipientTitle(data);
  const icon = useRecipientIcon(title);

  if (!data) {
    return <Box />;
  }

  return (
    <HStack spacing={2}>
      <Flex alignItems="center" position="relative">
        {icon}
      </Flex>
      <VStack spacing={0} alignItems="baseline">
        <Text textStyle={TextStyles.h4} color="primary.500">
          {title}
        </Text>
        <Text textStyle={TextStyles.Subtitle14Regular} color="primary.350">
          {localizedType}
        </Text>
      </VStack>
    </HStack>
  );
};

const Actions = ({
  isTransferAvailable,
}: {
  isTransferAvailable?: boolean;
}) => {
  const { t } = useTranslation(['recipients']);
  const showTransfer = useShowTransferModal();
  return (
    <Box px={3} py={2}>
      <Button
        variant="secondary"
        w="full"
        onClick={showTransfer}
        data-testid="button_transfer-money"
        isDisabled={!isTransferAvailable}
      >
        <Icon w={2} h={2} as={ArrowUp14Icon} mr={1} />
        {t('recipients:infoDrawer.transferMoney')}
      </Button>
      {/* Кнопка Delete удалена в рамках BANK-4862 */}
    </Box>
  );
};

const Body = ({
  isAccountsEmpty,
  isAvailableAccountsEmpty,
}: {
  isAccountsEmpty: boolean;
  isAvailableAccountsEmpty: boolean;
}) => {
  const { t } = useTranslation(['recipients']);
  const { data: recipient } = useRecipientQuery();
  const address = useParticipantAddress(recipient?.registrationAddress);

  const { label: bankInfoLabel, value: bankInfoValue } = useParticipantBankInfo(
    {
      name: recipient?.account.bankName,
      country: recipient?.account.bankCountry,
      bic: recipient?.account.bic,
    },
  );

  const alertDescription = useMemo(() => {
    if (isAccountsEmpty) return t('recipients:infoDrawer.noAccountsInfo');
    if (isAvailableAccountsEmpty)
      return t('recipients:infoDrawer.noAvailableAccountsInfo');
    return null;
  }, [isAccountsEmpty, isAvailableAccountsEmpty, t]);

  if (!recipient) {
    return <NoData />;
  }

  const recipientAccount = recipient.account;

  const curr = ALL_CURRENCIES.find(
    (_currency) => _currency.currencyCode === recipientAccount.currency,
  );

  return (
    <DrawerBody p={3}>
      <VStack spacing={2} alignItems="stretch">
        {alertDescription && (
          <Alert
            status="info"
            label={t('recipients:infoDrawer.info').toUpperCase()}
            description={alertDescription}
          />
        )}
        <InfoItem label={t('recipients:infoDrawer.address')} value={address} />
        <InfoItem
          label={t('recipients:infoDrawer.currency')}
          value={curr ? `${curr.name} (${curr.currencyCode})` : ''}
        />
        <InfoItem
          label={t('recipients:infoDrawer.iban')}
          value={recipientAccount.iban}
        />
        <InfoItem
          label={t('recipients:infoDrawer.accountNumber')}
          value={recipientAccount.accountNumber}
        />
        <InfoItem label={bankInfoLabel} value={bankInfoValue} />
      </VStack>
      <TransferModal initialRecipient={recipient} initialStep="form" />
    </DrawerBody>
  );
};
