import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { FloatingInputField } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import { BankLoader } from '../../components/BankLoader/BankLoader';
import {
  BankModalBody,
  BankModalFooter,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import ExchangeDropdownField from '../../components/ExchangeDropdown/ExchangeDropdownField';
import { useLayoutContext } from '../../context/LayoutContextProvider';
import { useAccountsQuery, useCNYAccount } from '../../hooks/accounts/queries';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { ArrowDownIcon, ArrowIcon } from '../../icons';
import {
  TConvertToCNYSelectForm,
  useConvertToCNYWizardContext,
} from './ConvertToCNYWizard';
import { useFormContext } from 'react-hook-form';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import { TAccountDto, TTransferFeeParams } from '@payler/api/client-office';
import { TExchangeDropdownOption } from '../../components/ExchangeDropdown/ExchangeDropdownOption';
import {
  getExchangeAccountOptionByAccount,
  getExchangeAccountsOptions,
} from '../Exchange/helpers';
import { useTransferFeeQuery } from '../../hooks/transfers/queries';

export const ConvertToCNYSelect: FC = () => {
  const { t } = useTranslation(['common', 'accounts']);
  const { formatNumber } = useLanguageFeatures();
  const {
    sendingAmount,
    exchangeRate,
    setStep,
    receivingAmount,
    detailedExchangeRate,
    senderAccount,
    setSenderAccount,
    receiverAccount,
    setReceiverAccount,
    isExchangeRateFetching,
    exchangeRateError,
    onAmountChange,
  } = useConvertToCNYWizardContext();
  const { isMobile } = useLayoutContext();
  const { data: accounts } = useAccountsQuery();
  const { data: transferFee, isFetching: isTransferFeeFetching } =
    useTransferFeeQuery(
      {
        BaasProvider: senderAccount?.baasProvider.shortName,
        TransferType: 'outgoing',
        Amount: sendingAmount,
        Currency: senderAccount?.currency,
        System: 'internal',
      } as TTransferFeeParams,
      !!senderAccount && !!sendingAmount,
    );

  const cnYAccount = useCNYAccount();
  if (!cnYAccount) throw Error('No cnY account found');

  const methods = useFormContext<TConvertToCNYSelectForm>();
  const {
    formState: { errors },
    trigger,
    clearErrors,
  } = methods;

  const handleSubmit = () => {
    trigger([
      'sendingAmount',
      'receivingAmount',
      'receiverAccId',
      'senderAccId',
    ]).then((isValid) => isValid && setStep('confirmation'));
  };

  const receiverAccountOptions = useMemo(() => {
    return getExchangeAccountsOptions([cnYAccount], formatNumber);
  }, [senderAccount, receiverAccount, accounts, formatNumber]);

  const onAccountChange = useCallback(
    (selectedAccountId: string, isSenderSelector: boolean) => {
      if (errors) {
        clearErrors('sendingAmount');
      }
      const selectedAccount = accounts?.find(
        (account) => selectedAccountId === account.id,
      );
      const setAccount = isSenderSelector
        ? setSenderAccount
        : setReceiverAccount;
      setAccount(selectedAccount);
    },
    [errors, accounts, setSenderAccount, setReceiverAccount, clearErrors],
  );

  const onSendingAmountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onAmountChange({ amount: e.target.value, amountDirection: 'sending' });
    },
    [onAmountChange],
  );

  const onReceivingAmountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onAmountChange({ amount: e.target.value, amountDirection: 'receiving' });
    },
    [onAmountChange],
  );

  return (
    <>
      <ModalHeaderActions />
      <BankModalTitle
        title={t('accounts:exchange.selectStep.title')}
        description={t('accounts:exchange.selectStep.description')}
      />
      <BankModalBody>
        <VStack spacing={3}>
          {isMobile ? (
            <MobileView
              senderAccount={senderAccount}
              receiverAccount={receiverAccount}
              receiverAccountOptions={receiverAccountOptions}
              onAccountChange={onAccountChange}
            />
          ) : (
            <DesktopView
              senderAccount={senderAccount}
              receiverAccountOptions={receiverAccountOptions}
              onAccountChange={onAccountChange}
            />
          )}
          <VStack spacing={2} w="full">
            <FloatingInputField
              value={sendingAmount}
              name="sendingAmount"
              variant="currency"
              currencySuffix={
                sendingAmount
                  ? senderAccount?.currency.toUpperCase()
                  : undefined
              }
              label={t('accounts:exchange.selectStep.youSpend')}
              onChange={(e) => onSendingAmountChange(e)}
              allowNegative={false}
            />

            <Text
              textStyle={TextStyles.Caption12Regular}
              color="primary.400"
              alignSelf="flex-end"
            >
              {isTransferFeeFetching
                ? t('common:loading')
                : t('accounts:transferMoney.transferFee', {
                    amount: transferFee?.feeAmount || 0,
                    currency: senderAccount?.currency.toUpperCase(),
                  })}
            </Text>

            <FloatingInputField
              value={receivingAmount}
              name="receivingAmount"
              variant="currency"
              currencySuffix={
                receivingAmount
                  ? receiverAccount?.currency.toUpperCase()
                  : undefined
              }
              label={t('accounts:exchange.selectStep.youGet')}
              onChange={(e) => onReceivingAmountChange(e)}
              allowNegative={false}
            />
          </VStack>
          <ExchangeRateBlock
            exchangeRateError={exchangeRateError}
            exchangeRate={exchangeRate}
            isExchangeRateFetching={isExchangeRateFetching}
            detailedExchangeRate={detailedExchangeRate}
          />
        </VStack>
      </BankModalBody>
      <BankModalFooter>
        <Button
          w="full"
          onClick={handleSubmit}
          isDisabled={!isEmpty(errors) || !exchangeRate}
        >
          {t('common:continue')}
        </Button>
      </BankModalFooter>
    </>
  );
};

const MobileView = ({
  senderAccount,
  receiverAccount,
  receiverAccountOptions,
  onAccountChange,
}: {
  senderAccount: TAccountDto | undefined;
  receiverAccount: TAccountDto | undefined;
  receiverAccountOptions: TExchangeDropdownOption[] | undefined;
  onAccountChange: (
    selectedAccountId: string,
    isSenderSelector: boolean,
  ) => void;
}) => {
  const { t } = useTranslation('accounts');
  const { formatNumber } = useLanguageFeatures();
  return (
    <VStack w="full">
      <Box w="inherit">
        <ExchangeDropdownField
          isDisabled
          fieldName="senderAccId"
          options={
            senderAccount
              ? [getExchangeAccountOptionByAccount(senderAccount, formatNumber)]
              : []
          }
        />
      </Box>
      <HStack w="inherit" justifyContent="space-between" px={1}>
        <Text>
          {t('exchange.selectStep.exchangeDirection', {
            senderCurrency: senderAccount?.currency.toUpperCase(),
            receiverCurrency: receiverAccount?.currency.toUpperCase(),
          })}
        </Text>
        <Box>
          <ArrowDownIcon />
        </Box>
      </HStack>
      <Box w="inherit">
        <ExchangeDropdownField
          fieldName="receiverAccId"
          options={receiverAccountOptions || []}
          onChange={(id) => onAccountChange(id as string, false)}
        />
      </Box>
    </VStack>
  );
};

const DesktopView = ({
  senderAccount,
  receiverAccountOptions,
  onAccountChange,
}: {
  senderAccount: TAccountDto | undefined;
  receiverAccountOptions: TExchangeDropdownOption[] | undefined;
  onAccountChange: (
    selectedAccountId: string,
    isSenderSelector: boolean,
  ) => void;
}) => {
  const { formatNumber } = useLanguageFeatures();
  return (
    <HStack spacing={1} width="full" justifyContent="space-between">
      <Box w="full">
        <ExchangeDropdownField
          isDisabled
          fieldName="senderAccId"
          options={
            senderAccount
              ? [getExchangeAccountOptionByAccount(senderAccount, formatNumber)]
              : []
          }
        />
      </Box>
      <Box w="24px">
        <ArrowIcon />
      </Box>
      <Box w="full">
        <ExchangeDropdownField
          fieldName="receiverAccId"
          options={receiverAccountOptions || []}
          onChange={(id) => onAccountChange(id as string, false)}
        />
      </Box>
    </HStack>
  );
};

const ExchangeRateBlock = ({
  exchangeRateError,
  exchangeRate,
  isExchangeRateFetching,
  detailedExchangeRate,
}: {
  exchangeRateError: unknown;
  exchangeRate: number | undefined;
  isExchangeRateFetching: boolean;
  detailedExchangeRate: string;
}) => {
  const { t } = useTranslation('accounts');
  if (exchangeRateError) {
    return (
      <ApiErrorText>{t('exchange.selectStep.exchangeRateError')}</ApiErrorText>
    );
  }
  if (isExchangeRateFetching) {
    return (
      <Box h="55px">
        <BankLoader />
      </Box>
    );
  }

  return (
    !isNil(exchangeRate) && (
      <HStack w="full" justifyContent="center">
        <Box>
          <Text
            textStyle={TextStyles.Caption12Regular}
            color="primary.350"
            textAlign="center"
          >
            {t('exchange.selectStep.exchangeRate')}
          </Text>
          <Text textStyle={TextStyles.tables}>{detailedExchangeRate}</Text>
        </Box>
      </HStack>
    )
  );
};
