import { TParticipantAccountDto, TRecipientDto } from '../types';

const mockRecipientAccount: TParticipantAccountDto = {
  ledgerAccountId: '101',
  iban: 'XXXXXXXXXXXXXXXXXXXX',
  accountNumber: '30151928505101',
  bic: '11111111',
  bankCountry: '',
  bankName: null,
  currency: 'EUR',
};

export const mockRecipients: TRecipientDto[] = [
  {
    id: '1',
    created: '',
    modified: '',
    recipientType: 'corporate',
    legalName: 'Name 1',
    registrationAddress: {
      country: '',
      postalCode: '',
      city: '',
      street: '',
    },
    account: mockRecipientAccount,
    isVisible: true,
  },
  {
    id: '2',
    created: '',
    modified: '',
    recipientType: 'corporate',
    legalName: 'Name 2',
    registrationAddress: {
      country: '',
      postalCode: '',
      city: '',
      street: '',
    },
    account: mockRecipientAccount,
    isVisible: true,
  },
  {
    id: '3',
    created: '',
    modified: '',
    recipientType: 'corporate',
    legalName: 'Name 3',
    registrationAddress: {
      country: '',
      postalCode: '',
      city: '',
      street: '',
    },
    account: mockRecipientAccount,
    isVisible: true,
  },
  {
    id: '4',
    created: '',
    modified: '',
    recipientType: 'individual',
    firstName: 'Name',
    lastName: '4',
    registrationAddress: {
      country: '',
      postalCode: '',
      city: '',
      street: '',
    },
    account: mockRecipientAccount,
    isVisible: true,
  },
  {
    id: '5',
    created: '',
    modified: '',
    recipientType: 'individual',
    firstName: 'Name',
    lastName: '5',
    registrationAddress: {
      country: '',
      postalCode: '',
      city: '',
      street: '',
    },
    account: mockRecipientAccount,
    isVisible: true,
  },
  {
    id: '6',
    created: '',
    modified: '',
    recipientType: 'corporate',
    legalName: 'Name 6',
    registrationAddress: {
      country: '',
      postalCode: '',
      city: '',
      street: '',
    },
    account: mockRecipientAccount,
    isVisible: true,
  },
];
