import React from 'react';
import { BankModal } from '../../components/BankModal/BankModal';
import { useRecoilBoolean, useRecoilBooleanValue } from '@payler/bank-utils';
import { ConvertToCNYWizard } from './ConvertToCNYWizard';

const KEY = 'modals:ConvertToCNYModal';

export const useShowConvertToCNYModal = () => useRecoilBoolean(KEY).on;
export const useCloseConvertToCNYModal = () => useRecoilBoolean(KEY).off;
export const useIsOpenConvertToCNYModal = () => useRecoilBooleanValue(KEY);

export const ConvertToCNYModal: React.FC = () => {
  const open = useIsOpenConvertToCNYModal();
  const closeModal = useCloseConvertToCNYModal();

  return (
    <BankModal close={closeModal} open={open}>
      <ConvertToCNYWizard />
    </BankModal>
  );
};
