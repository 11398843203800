import { CommonOtp } from '../TransferModal/CommonOtp';
import { useConvertToCNYWizardContext } from './ConvertToCNYWizard';
import { useCloseConvertToCNYModal } from './ConvertToCNYModal';

export const ConvertToCNYOtp = () => {
  const { transferId, setStep } = useConvertToCNYWizardContext();
  const onSuccess = () => setStep('success');
  const onBack = () => setStep('confirmation');
  const close = useCloseConvertToCNYModal();

  return (
    <CommonOtp
      transferId={transferId}
      close={close}
      onSuccess={onSuccess}
      onBack={onBack}
      isOutgoingTransfer={true}
    />
  );
};
