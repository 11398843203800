import React, { useMemo, useState } from 'react';
import {
  DrawerBody,
  DrawerHeader,
  HStack,
  VStack,
  Text,
  Box,
  Flex,
  IconButton,
  Button,
  Icon,
} from '@chakra-ui/react';
import { NoData, ThinDivider, Alert } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import { BankDrawer } from '../../components/BankDrawer/BankDrawer';
import { BankDrawerContent } from '../../components/BankDrawer/BankDrawerContent';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import {
  useOutgoingTransferIdFromUrl,
  useSetOutgoingTransferIdToUrl,
  useOutgoingTransferByUrl,
} from '../../hooks/transfers/queries';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { TransferIconWithStatus } from '../../components/TransferIconWithStatus/TransferIconWithStatus';
import { useTranslation } from 'react-i18next';
import { CrossIcon, ExchangeIcon } from '@payler/ui-icons';
import { InfoItem } from '../helpers/InfoItem';
import { TransferStatementButton } from '../../components/TransferStatementButton/TransferStatementButton';
import { TransferModal } from '../../modals/TransferModal/TransferModal';
import { useAccountQuery } from '../../hooks/accounts/queries';
import { TTransferDetailsForm } from '../../modals/TransferModal/TransferWizard';
import { useParticipantBankInfo } from '../../hooks/use-participant-bank-info';
import { DocumentsWrapper } from '../../components/DocumentLink/DocumentsWrapper';
import { getTransferStatus } from '../helpers/get-transfer-status';
import { SelfTransferAccountInfo } from '../helpers/SelfTransferAccountInfo';
import { isNil } from 'lodash';
import { useParticipantAddress } from '../../helpers/use-participant-address';

export const OutgoingTransferInfoDrawer = () => {
  const outgoingTransferId = useOutgoingTransferIdFromUrl();
  const isOpen = !!outgoingTransferId;
  const setOutgoingTransferId = useSetOutgoingTransferIdToUrl();

  return (
    <BankDrawer isOpen={isOpen} onClose={setOutgoingTransferId}>
      <BankDrawerContent drawerId="outgoing-transfer-info-drawer">
        <React.Suspense fallback={<LoaderBox />}>
          <Header />
          <ThinDivider />
          <Body />
        </React.Suspense>
      </BankDrawerContent>
    </BankDrawer>
  );
};

const Header = () => {
  const transfer = useOutgoingTransferByUrl();

  const isTransferStatementAvailable =
    !!transfer?.id && transfer?.operationType !== 'paymentRequest';

  return (
    <HeaderWrapper isTransferStatementAvailable={isTransferStatementAvailable}>
      <HeaderComponent
        isTransferStatementAvailable={isTransferStatementAvailable}
      />
    </HeaderWrapper>
  );
};

const HeaderWrapper: FCC<{ isTransferStatementAvailable: boolean }> = ({
  isTransferStatementAvailable,
  children,
}) => {
  const outgoingTransferId = useOutgoingTransferIdFromUrl();
  const setOutgoingTransferId = useSetOutgoingTransferIdToUrl();

  if (!outgoingTransferId) return null;

  return (
    <DrawerHeader p={3}>
      <HStack spacing={2} justifyContent="space-between">
        {children}
        <HStack spacing={2}>
          {isTransferStatementAvailable && (
            <TransferStatementButton transferId={outgoingTransferId} />
          )}
          <IconButton
            icon={<CrossIcon />}
            aria-label="close"
            variant="secondary"
            w={6}
            h={6}
            onClick={() => {
              setOutgoingTransferId();
            }}
            data-testid="button_drawer-close"
          />
        </HStack>
      </HStack>
    </DrawerHeader>
  );
};

const HeaderComponent = ({
  isTransferStatementAvailable,
}: {
  isTransferStatementAvailable: boolean;
}) => {
  const { t } = useTranslation(['transfers']);
  const { serverDatetimeToDisplay } = useLanguageFeatures();
  const transfer = useOutgoingTransferByUrl();

  const isSelfTransfer = transfer?.operationType === 'self';

  if (!transfer) {
    return <Box />;
  }

  return (
    <HStack spacing={2}>
      <Flex alignItems="center" position="relative">
        <TransferIconWithStatus
          status={transfer.state}
          type="outgoing"
          isSelfTransfer={isSelfTransfer}
        />
      </Flex>
      <VStack spacing={0} alignItems="baseline">
        <Text
          textStyle={TextStyles.h4}
          color="primary.500"
          maxW={isTransferStatementAvailable ? '200px' : '270px'}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {isSelfTransfer
            ? t('transfers:transferToSelf')
            : transfer.recipient.legalName}
        </Text>
        <Text textStyle={TextStyles.Subtitle14Regular} color="primary.350">
          {serverDatetimeToDisplay(transfer?.created, 'datetime')}
        </Text>
      </VStack>
    </HStack>
  );
};

const Body = () => {
  const { t } = useTranslation(['transfers']);
  const [isOpenTransferModal, setIsOpenTransferModal] =
    useState<boolean>(false);
  const transfer = useOutgoingTransferByUrl();
  const { formatAmountByCurrency, serverDatetimeToDisplay } =
    useLanguageFeatures();
  const { data: senderAccount } = useAccountQuery(
    transfer?.sender.account.ledgerAccountId || '',
  );

  const recipientAddress = useParticipantAddress(
    transfer?.recipient?.registrationAddress,
  );

  const initialFormValues = useMemo(
    (): TTransferDetailsForm => ({
      amount: transfer?.senderSide.amount || 0,
      description: transfer?.description || '',
      senderAccountId: senderAccount?.id,
      receiverAccountId:
        transfer?.recipient.account.ledgerAccountId || undefined,
    }),
    [transfer?.description, transfer?.senderSide.amount, senderAccount?.id],
  );

  const { label: bankInfoLabel, value: bankInfoValue } = useParticipantBankInfo(
    {
      name: transfer?.recipient?.account.bankName,
      country: transfer?.recipient?.account.bankCountry,
      bic: transfer?.recipient?.account.bic,
    },
  );

  const transferAlertStatus = getTransferStatus(transfer?.state);

  const isSelfTransfer = transfer?.operationType === 'self';

  if (!transfer) {
    return <NoData />;
  }

  return (
    <DrawerBody p={0}>
      <VStack spacing={0} alignItems="stretch" divider={<ThinDivider />}>
        {transfer.operationType !== 'paymentRequest' && (
          <Box mx={3} my={2}>
            <Button
              variant="secondary"
              w="full"
              onClick={() => setIsOpenTransferModal(true)}
              data-testid="button_repeat-transfer"
            >
              <HStack spacing={1}>
                <Icon as={ExchangeIcon} />
                <Text textStyle={TextStyles.Buttons16Medium}>
                  {t('transfers:repeat')}
                </Text>
              </HStack>
            </Button>
          </Box>
        )}
        <VStack spacing={2} alignItems="stretch" p={3}>
          {transfer.reason && transferAlertStatus && (
            <Alert
              status={transferAlertStatus}
              label={t('transfers:reason').toUpperCase()}
              description={transfer.reason}
            />
          )}
          {isSelfTransfer ? (
            <SelfTransferAccountInfo
              senderAccountNumber={transfer.sender.account.accountNumber}
              receiverAccountNumber={
                transfer.recipient.account.accountNumber ||
                transfer.recipient.account.iban
              }
            />
          ) : (
            <>
              <Text as="h4" textStyle={TextStyles.h4}>
                {t('transfers:recipientInfo')}
              </Text>
              <InfoItem
                label={t('transfers:recipient')}
                value={transfer.recipient.legalName}
              />
              <InfoItem
                label={t('transfers:address')}
                value={recipientAddress}
              />
              {transfer.recipient?.account.iban && (
                <InfoItem
                  label={t('transfers:iban')}
                  value={transfer.recipient?.account.iban}
                />
              )}
              {transfer.recipient?.account.accountNumber && (
                <InfoItem
                  label={t('transfers:accountNumber')}
                  value={transfer.recipient?.account.accountNumber}
                />
              )}
              <InfoItem label={bankInfoLabel} value={bankInfoValue} />
            </>
          )}
        </VStack>
        <VStack spacing={2} alignItems="stretch" p={3}>
          <Text as="h4" textStyle={TextStyles.h4}>
            {t('transfers:transferInfo')}
          </Text>
          <InfoItem
            label={t('transfers:date')}
            value={serverDatetimeToDisplay(transfer?.created, 'datetime')}
          />
          <InfoItem label={t('transfers:transferId')} value={transfer.id} />
          <InfoItem
            label={t('transfers:amount')}
            value={formatAmountByCurrency(
              transfer.senderSide.amount || 0,
              transfer.senderSide.currency,
            )}
          />
          {!isNil(transfer.senderSide.feeAmount) && (
            <InfoItem
              label={t('transfers:fee')}
              value={formatAmountByCurrency(
                transfer.senderSide.feeAmount || 0,
                transfer.senderSide.currency,
              )}
            />
          )}
          {!isNil(transfer.senderSide.totalAmount) && (
            <InfoItem
              label={t('transfers:totalAmount')}
              value={formatAmountByCurrency(
                transfer.senderSide.totalAmount,
                transfer.senderSide.currency,
              )}
            />
          )}
          {!isSelfTransfer && (
            <InfoItem
              label={t('transfers:senderAccount')}
              value={transfer.sender.account.accountNumber}
            />
          )}
          <InfoItem
            label={t('transfers:status')}
            value={t(`transfers:statuses.${transfer.state}`)}
          />
          {!isSelfTransfer && (
            <>
              <InfoItem label={t('transfers:system')} value={transfer.system} />
              <InfoItem
                label={t('transfers:description')}
                value={transfer.description ?? ''}
              />
              {transfer.documents && transfer.documents.length > 0 && (
                <InfoItem
                  label={t('transfers:attachedDocuments')}
                  value={
                    <DocumentsWrapper
                      documents={transfer.documents}
                      isClickable={true}
                    />
                  }
                />
              )}
            </>
          )}
        </VStack>
      </VStack>
      <TransferModal
        isOpen={isOpenTransferModal}
        close={() => setIsOpenTransferModal(false)}
        initialRecipient={transfer.recipient}
        initialStep="form"
        initialFormValues={initialFormValues}
        initialSenderAccount={senderAccount}
        isSelfTransfer={isSelfTransfer}
        isRepeatTransfer
      />
    </DrawerBody>
  );
};
