import { Circle, HStack, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { ClockIcon, SuccessBigIcon, UsersIcon } from '../../icons';
import { ReactComponent } from '*.svg';
import { UTM_LIST } from './OnboardingPage';
import { OnboardingWrapper } from './OnboardingWrapper';
import { useAnalyticsManager } from '@payler/analytics';
import { useClearUTMs } from '@payler/bank-utils';
import { useAuth } from '@payler/auth';
import { useCompanyInfo } from '../../config/ConfigProvider';

export const SuccessPage: FC = () => {
  const { t } = useTranslation(['onboarding']);
  const analyticsManager = useAnalyticsManager();
  const { updateToken } = useAuth();
  const { onboardingEmail, onboardingPhoneNumber } = useCompanyInfo();

  // Удаление utm-меток из куки после прохождения онбординга
  useClearUTMs(UTM_LIST);

  useEffect(() => {
    analyticsManager.sendPaymentSuccessfulEvent();
  }, [analyticsManager]);

  /* Периодическая проверка токена. Пользователь GlobalAccounts должен перенаправиться в личный
  кабинет после активации. Но клейм GlobalAccounts может прийти с задержкой. */
  useEffect(() => {
    updateToken();
    const interval = setInterval(updateToken, 5000);
    const timeout = setTimeout(() => clearInterval(interval), 600_000);
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <OnboardingWrapper>
      <Icon as={SuccessBigIcon} width="64px" height="64px" color="brands.500" />
      <VStack spacing={3}>
        <Text as="h1" textStyle={TextStyles.h1}>
          {t('onboarding:successPage.successTitle')}
        </Text>
        <InfoItem
          icon={UsersIcon}
          label={t('onboarding:successPage.successInfo1')}
        />
        <InfoItem
          icon={ClockIcon}
          label={t('onboarding:successPage.successInfo2')}
        />
      </VStack>
      <HStack justify={'space-between'} w="100%">
        <Link
          isExternal
          href={`mailto:${onboardingEmail}`}
          textStyle={TextStyles.BodyText16Medium}
          color="brands.500"
        >
          {onboardingEmail}
        </Link>
        <Link
          isExternal
          href={`tel:${onboardingPhoneNumber}`}
          textStyle={TextStyles.BodyText16Medium}
          color="brands.500"
        >
          {onboardingPhoneNumber}
        </Link>
      </HStack>
    </OnboardingWrapper>
  );
};

const InfoItem: FC<{
  icon: typeof ReactComponent;
  label: string;
}> = ({ icon, label }) => {
  return (
    <HStack
      alignItems="center"
      p={2}
      bg="secondary.500"
      borderRadius={1.5}
      spacing={2}
      w="100%"
    >
      <Circle size={5} p="10px" bg="brands.100">
        <Icon as={icon} color="brands.500" w={2.5} h={2.5} />
      </Circle>
      <Text
        textStyle={TextStyles.Subtitle14Regular}
        color="primary.500"
        align="left"
      >
        {label}
      </Text>
    </HStack>
  );
};
