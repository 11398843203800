import React from 'react';
import { BankModal } from '../../components/BankModal/BankModal';
import { useRecoilBoolean, useRecoilBooleanValue } from '@payler/bank-utils';
import { AddAccountWizard } from './AddAccountWizard';

const KEY = 'modals:AddAccountModal';

export const useShowAddAccountModal = () => useRecoilBoolean(KEY).on;
export const useCloseAddAccountModal = () => useRecoilBoolean(KEY).off;
export const useIsOpenAddAccountModal = () => useRecoilBooleanValue(KEY);

export const AddAccountModal: React.FC = () => {
  const isOpen = useIsOpenAddAccountModal();
  const close = useCloseAddAccountModal();

  return (
    <BankModal close={close} open={isOpen} closeOnOverlayClick={false}>
      <AddAccountWizard />
    </BankModal>
  );
};
