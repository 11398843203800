import { Trans, useTranslation } from 'react-i18next';
import { EventStatus } from '../../components/EventStatus/EventStatus';
import { useTransferWizardContext } from './TransferWizard';
import {
  REQUISITES_NOT_FOUND_ERROR,
  useGetAxiosError,
} from '@payler/bank-utils';
import { useMemo } from 'react';
import { Link, Text } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { useCompanyInfo } from '../../config/ConfigProvider';

export const TransferError = ({ close }: { close: VoidFunction }) => {
  const { t } = useTranslation(['accounts']);
  const { error } = useTransferWizardContext();
  const getError = useGetAxiosError();
  const { infoEmail } = useCompanyInfo();

  const description = useMemo(() => {
    const { fieldErrors } = getError(error);
    if (fieldErrors?.[0]?.fieldErrorCode === REQUISITES_NOT_FOUND_ERROR) {
      return (
        <Text
          textStyle={TextStyles.BodyText16Regular}
          textAlign="center"
          color="primary.400"
        >
          <Trans
            i18nKey="accounts:transferMoney.error.requisitesNotFound"
            values={{ email: infoEmail }}
            components={{
              emailLink: (
                <Link
                  isExternal
                  href={`mailto:${infoEmail}`}
                  textStyle={TextStyles.BodyText16Medium}
                  colorScheme="primary"
                />
              ),
            }}
            ns={['accounts']}
          />
        </Text>
      );
    }
    return null;
  }, [getError, error]);

  return (
    <EventStatus
      statusType="warning"
      title={t('accounts:transferMoney.error.title')}
      description={description}
      onCloseButtonClick={() => {
        close();
      }}
      closeButtonProps={{ variant: 'secondary' }}
    />
  );
};
