import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import {
  BankModalBody,
  BankModalFooter,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import { Button, VStack } from '@chakra-ui/react';
import {
  ApiErrorText,
  DropdownField,
  FloatingInputField,
} from '@payler/ui-components';
import { COUNTRY_OPTIONS } from '../../const';
import { useAddRecipientWizardContext } from './AddRecipientWizard';
import { TCreateRecipientFields } from '@payler/api/client-office';
import {
  useGetAxiosError,
  useHandleFormError,
  useToasts,
} from '@payler/bank-utils';
import { useAddRecipientMutation } from '../../hooks/recipients/mutations';
import { fieldsMap, getStructuredRecipientParam } from './helpers';
import { useApi } from '../../state/api';
import { useAccountsCurrenсyOptions } from '../../hooks/use-accounts-currency-options'

export const AccountInfoForm = () => {
  const { setStep, setRecipient, onFinishWizard, senderAccount } =
    useAddRecipientWizardContext();
  const api = useApi();
  const toasts = useToasts();
  const getError = useGetAxiosError();
  const currencyOptions = useAccountsCurrenсyOptions();

  const { t } = useTranslation(['recipients', 'common']);

  const { mutate: addRecipient, isPending } = useAddRecipientMutation();
  const handleFormError = useHandleFormError<TCreateRecipientFields>();
  const [errorText, setErrorText] = useState('');

  const methods = useFormContext<TCreateRecipientFields>();
  const {
    handleSubmit: rhfHandleSubmit,
    formState: { errors },
    getValues,
    trigger,
  } = methods;

  const recipientFieldValues = getValues();
  const createRecipientParam = getStructuredRecipientParam({
    recipientFieldValues,
    isVisible: !setRecipient,
  });

  const handleSubmit = rhfHandleSubmit(() => {
    addRecipient(createRecipientParam, {
      onSuccess: async (recipientId: string) => {
        if (setRecipient) {
          try {
            const createdRecipient = await api.getRecipientById(recipientId);
            setRecipient(createdRecipient);
          } catch (e) {
            toasts.error(getError(e).errorTitle);
          } finally {
            onFinishWizard?.();
          }
        } else {
          setStep('success');
        }
      },
      onError: (e: unknown) => {
        const unknownFieldErrors = handleFormError(e, methods, fieldsMap);
        if (unknownFieldErrors.length)
          setErrorText(unknownFieldErrors.join(' '));
        throw e;
      },
    });
  });

  const onConfirmClick = useCallback(
    () =>
      trigger([
        'accountIdentifier',
        'currency',
        'bic',
        'bankCountry',
        'bankName',
      ]).then((isValid) => {
        if (isValid) handleSubmit();
      }),
    [handleSubmit, trigger],
  );

  return (
    <>
      <ModalHeaderActions
        isShowBack={true}
        onBack={() => setStep('recipientInfo')}
      />
      <BankModalTitle
        title={t('recipients:addModal.accountInfo')}
        description={t('recipients:addModal.description')}
      />
      <BankModalBody>
        <VStack width="100%" spacing={2}>
          <FloatingInputField
            name="accountIdentifier"
            label={t('recipients:addModal.accountIdentifier')}
          />
          <DropdownField
            options={currencyOptions}
            fieldName="currency"
            label={t('recipients:addModal.accountCurrency')}
            floating
            isError={!!errors.currency}
            testId="field_currency"
            isDisabled={!!senderAccount}
          />
          <FloatingInputField name="bic" label={t('recipients:addModal.bic')} />
          <DropdownField
            options={COUNTRY_OPTIONS}
            fieldName="bankCountry"
            label={t('recipients:addModal.bankCountry')}
            floating
            isSearchable
            isError={!!errors.bankCountry}
            autocomplete="none"
            testId="field_recipient-bank-country"
          />
          <FloatingInputField
            name="bankName"
            label={t('recipients:addModal.bankName')}
          />
          {errorText && <ApiErrorText>{errorText}</ApiErrorText>}
        </VStack>
      </BankModalBody>
      <BankModalFooter>
        <Button
          w="full"
          onClick={onConfirmClick}
          isLoading={isPending}
          data-testid="button_continue"
        >
          {setRecipient ? t('common:continue') : t('common:confirm')}
        </Button>
      </BankModalFooter>
    </>
  );
};
