import { Button, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { InfoIcon } from '@chakra-ui/icons';
import { OnboardingWrapper } from './OnboardingWrapper';
import { useOnboardingPaymentQuery } from '../../hooks/onboarding/queries';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import { useCurrencyCallbacks } from '../../hooks/currencies-info';
import { useGetAxiosError } from '@payler/bank-utils';
import { useAnalyticsManager } from '@payler/analytics';
import { useCompanyInfo } from '../../config/ConfigProvider';

export const PaymentPage: FC = () => {
  const { t } = useTranslation(['onboarding']);
  const { data, error, isError, isFetching } = useOnboardingPaymentQuery();
  const { formatNumber } = useLanguageFeatures();
  const { getCurrencyUnitRate } = useCurrencyCallbacks();
  const getError = useGetAxiosError();
  const analyticsManager = useAnalyticsManager();
  const { companyName } = useCompanyInfo();

  return (
    <OnboardingWrapper>
      <VStack
        spacing={2}
        alignItems="stretch"
        p={3}
        bg="secondary.500"
        borderRadius={1.5}
        textAlign="left"
      >
        <Text as="h1" textStyle={TextStyles.h1}>
          {t('onboarding:paymentPage.title')}
        </Text>
        <Text textStyle={TextStyles.Subtitle14Regular} color="primary.500">
          {t('onboarding:paymentPage.subtitle', { companyName })}
        </Text>
        <Divider />
        <InfoItem
          title={t('onboarding:paymentPage.maintenanceFee')}
          text={t('onboarding:paymentPage.maintenanceFeeText')}
        />
        <InfoItem
          title={t('onboarding:paymentPage.fastTrackReview')}
          text={t('onboarding:paymentPage.fastTrackReviewText')}
        />
        <InfoItem
          title={t('onboarding:paymentPage.kybKycCompliance')}
          text={t('onboarding:paymentPage.kybKycComplianceText')}
        />
        <Divider />
        <VStack
          alignItems="stretch"
          p={2}
          bg="yellow.100"
          borderRadius={1.5}
          spacing={1}
        >
          <HStack spacing={0.5}>
            <InfoIcon w={1.5} color="yellow.500" />
            <Text textStyle={TextStyles.Tagline10Bold} color="primary.500">
              {t('onboarding:paymentPage.important').toUpperCase()}
            </Text>
          </HStack>
          <Text textStyle={TextStyles.Subtitle14Regular} color="primary.500">
            {t('onboarding:paymentPage.importantText')}
          </Text>
        </VStack>
        {isFetching && (
          <Text
            textStyle={TextStyles.Caption12Regular}
            color="primary.400"
            align="center"
          >
            {t('onboarding:paymentPage.loadingMessage')}
          </Text>
        )}
        {isError && getError(error).errorCode === 'FAILED_CREATE_PAYMENT' && (
          <ApiErrorText>
            {t('onboarding:paymentPage.failedCreatePaymentMessage')}
          </ApiErrorText>
        )}
        <Button
          onClick={() => {
            analyticsManager.sendPaymentClickEvent();
            data?.url && (window.location.href = data.url);
          }}
          variant="primary"
          isLoading={isFetching}
          isDisabled={isError}
        >
          {t('onboarding:paymentPage.buttonText', {
            price:
              data?.amount &&
              formatNumber(data.amount / getCurrencyUnitRate(data.currency)),
            currency: data?.amount && data.currency.toUpperCase(),
          })}
        </Button>
      </VStack>
    </OnboardingWrapper>
  );
};

const InfoItem: FC<{
  title: string;
  text: string;
}> = ({ title, text }) => {
  return (
    <VStack alignItems="stretch" spacing={0}>
      <Text textStyle={TextStyles.Subtitle14Semibold} color="primary.500">
        {title}
      </Text>
      <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
        {text}
      </Text>
    </VStack>
  );
};
