import React from 'react';
import { BankModal } from '../../components/BankModal/BankModal';
import { useRecoilBoolean, useRecoilBooleanValue } from '@payler/bank-utils';
import { ExchangeWizard } from './ExchangeWizard';

const KEY = 'modals:ExchangeModal';

export const useShowExchangeModal = () => useRecoilBoolean(KEY).on;
export const useCloseExchangeModal = () => useRecoilBoolean(KEY).off;
export const useIsOpenExchangeModal = () => useRecoilBooleanValue(KEY);

export const ExchangeModal: React.FC = () => {
  const open = useIsOpenExchangeModal();
  const closeModal = useCloseExchangeModal();

  return (
    <BankModal close={closeModal} open={open} closeOnOverlayClick={false}>
      <ExchangeWizard />
    </BankModal>
  );
};
