import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useCloseAddAccountModal } from './AddAccount';
import { ALL_CURRENCIES, TCurrency } from '@payler/bank-utils';
import { SelectCurrencyStep } from './SelectCurrencyStep';
import { AddAccountOTPForm } from './AddAccountOTPForm';
import { BankModalBody } from '../../components/BankModal/BankModal';
import { EventStatus } from '../../components/EventStatus/EventStatus';

type TAddAccountWizardStep = 'selectCurrency' | 'otp' | 'success' | 'failed';

type TAddAccountWizardContext = {
  step: TAddAccountWizardStep;
  setStep: Dispatch<SetStateAction<TAddAccountWizardStep>>;
  currency?: TCurrency;
  setCurrency: Dispatch<SetStateAction<TCurrency | undefined>>;
  phoneNumber?: string;
  setPhoneNumber: Dispatch<SetStateAction<string | undefined>>;
  correlationId?: string;
  setCorrelationId: Dispatch<SetStateAction<string | undefined>>;
  failInfo?: TAddAccountFailInfo;
  setFailInfo: Dispatch<SetStateAction<TAddAccountFailInfo | undefined>>;
  accountName?: string;
  setAccountName: Dispatch<SetStateAction<string | undefined>>;
};

export type TAddAccountFailInfo = {
  title: string;
  description?: string;
};

const AddAccountWizardContext = createContext<TAddAccountWizardContext>(
  {} as TAddAccountWizardContext,
);

export const useAddAccountWizardContext = () =>
  useContext(AddAccountWizardContext);

export const AddAccountWizard: React.FC = () => {
  const { t } = useTranslation(['accounts']);
  const close = useCloseAddAccountModal();
  const [step, setStep] = useState<TAddAccountWizardStep>('selectCurrency');
  const [currency, setCurrency] = useState<TCurrency>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [correlationId, setCorrelationId] = useState<string>();
  const [failInfo, setFailInfo] = useState<TAddAccountFailInfo>();
  const [accountName, setAccountName] = useState<string>();

  const ctxValue: TAddAccountWizardContext = useMemo(
    () => ({
      step,
      setStep,
      currency,
      setCurrency,
      phoneNumber,
      setPhoneNumber,
      correlationId,
      setCorrelationId,
      failInfo,
      setFailInfo,
      accountName,
      setAccountName,
    }),
    [correlationId, currency, failInfo, phoneNumber, step, accountName],
  );

  return (
    <AddAccountWizardContext.Provider value={ctxValue}>
      {step === 'selectCurrency' && <SelectCurrencyStep />}
      {step === 'otp' && <AddAccountOTPForm />}
      {step === 'failed' && (
        <BankModalBody>
          <EventStatus
            statusType="error"
            title={failInfo?.title}
            description={failInfo?.description}
            onCloseButtonClick={close}
          />
        </BankModalBody>
      )}
      {step === 'success' && currency && (
        <BankModalBody>
          <EventStatus
            statusType="success"
            title={t('accounts:addAccountModal.accountAdded')}
            description={accountName}
            onCloseButtonClick={close}
          />
        </BankModalBody>
      )}
    </AddAccountWizardContext.Provider>
  );
};
